header {
  background-color: $primary-color-80;
  height: rem-calc(65);
  padding: rem-calc(5) $side-padding;

  .header-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .header-link {
    display: block;
    color: $secondary-color;
    text-decoration: none;
    padding: 0;
    margin: 0;

    h1 {
      padding: 0;
      margin: 0;
    }

    h2 {
      padding: 0;
      margin: 0;
    }
  }

  .logo-link {
    display: block;
    height: calc(#{$header-height-small});

    .logo {
      padding-top: #{rem-calc(5)};
      height: inherit;
    }

    .logo-customer {
      height: rem-calc(55);
      margin-right: rem-calc(30);
      cursor: default;
    }
  }
}

.MuiDialogActions-root {
  padding: 8px 24px !important;
}