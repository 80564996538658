.tmt_green_bg{
    background-color: #008000;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 17px;
    vertical-align: sub;
}

.tmt_deviation_bg{
    background-color: #FF0000;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 3px;
    vertical-align: sub;
}

.general_camera_event_text{
    margin-left: 40px;
}

.tmt_deviation_event_text{
    margin-left: 9px;
}

.pin-push{
    width: 80%;
    margin-top: 10px;
    transform: rotate(45deg);
    margin-left: -16px;
    margin-right: -7px;
}

.pin-push-icon{
    padding: 0!important;
    height: 20px;
}

.img-card{
    background-color: azure;
    color: rgb(0, 85, 145);
}

.multi-image-container{
    padding: 10px;
}

.multi-camera-color-box{
    margin-left: 20px;
    float: right;
}

.multi-camera-color-box-galleryMinTempValue{
    margin-left: 5%;
    position: absolute;
    margin-top: -2%;
}

.multi-camera-color-box-galleryMaxTempValue{
    margin-left: 47%;
    position: absolute;
    margin-top: -2%;
}

.image-card-header{
    padding: 0 !important;
    margin: 0;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
}

.image-card-header-typography{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.small-image-card-holder{
    color: rgb(0, 85, 145);
    border: unset !important;
    background-color: unset !important;
}

.multi-camera-image{
    max-width: none;
    width: 100%;
    height: 90%;
}

.tmtgen-holder{
    padding-bottom: 0px;
}

.multi-camera-panel{
    position: relative;
}

.multi-camera-right-panel-holder{
    display: grid;
}
.multi-camera-right-panel{
    position: absolute;
    right: 0;
}

.devaition-legend-holder{
    margin-top: 2%;
}
.action-padd{
    margin: 0
}

.MuiCardHeader-action{
    margin:0 !important
}

.image-date-time{
    //margin-left: 30%;
    font-size: 14px;
}